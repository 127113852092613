@import './vars.less';
body {
  background-color: #f4f4f4 !important;
}




.datatable-wrapper {
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    .global-actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
      min-height: 30px;
      button {
        margin: auto;
        &:first-child {
          margin-right: 10px;
        }
        &.icon-btn {
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              margin-right: 5px;
            }
          }
        }
      }
    }
    .server-side-filters {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      &.no-margin {
        margin: 0;
      }
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 5px;
        &.dateFilterForm {
          .form-group {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
        .form-group {
          margin: 0;
          fieldset {
            border: 1px solid #e9ecef;
            padding: 5px;
            legend {
              width: auto;
              font-size: 16px;
              margin: 0;
            }
          }
          .input-wrapper {
            &.status-filter-wrapper {
              position: relative;
              .ng-select {
                min-width: 160px;
                max-width: 160px;
                &:focus {
                  outline: none;
                }
                &.ng-select-single {
                  &.ng-select-focused {
                    .ng-select-container {
                      border-color: #dddddd;
                      box-shadow: none;
                    }
                  }
                  .ng-select-container {
                    min-height: 30px;
                    height: 30px;
                    position: relative;
                    border: 1px solid #dddddd;
                    .ng-clear-wrapper {
                      display: flex;
                    }
                    .ng-arrow-wrapper {}
                    .ng-value-container {
                      padding-right: 50px;
                      .ng-value {
                        font-size: 14px;
                      }
                      .ng-placeholder {
                        font-size: 14px;
                      }
                      .ng-input {
                        height: 30px;
                        top: 50%;
                        margin-top: -15px;
                      }
                    }
                  }
                }
              }
              .ng-dropdown-panel {
                min-width: 160px!important;
                max-width: 160px!important;
                .ng-option {
                  font-size: 12px;
                  white-space: unset;
                }
              }
            }
            &.club-filter-wrapper {
              position: relative;
              .ng-select {
                min-width: 200px;
                &:focus {
                  outline: none;
                }
                &.ng-select-single {
                  &.ng-select-focused {
                    .ng-select-container {
                      border-color: #dddddd;
                      box-shadow: none;
                    }
                  }
                  .ng-select-container {
                    min-height: 30px;
                    height: 30px;
                    position: relative;
                    border: 1px solid #dddddd;
                    .ng-clear-wrapper {
                      display: flex;
                    }
                    .ng-arrow-wrapper {}
                    .ng-value-container {
                      padding-right: 50px;
                      .ng-value {
                        font-size: 14px;
                      }
                      .ng-placeholder {
                        font-size: 14px;
                      }
                      .ng-input {
                        height: 30px;
                        top: 50%;
                        margin-top: -15px;
                      }
                    }
                  }
                }
              }
              .ng-dropdown-panel {
                min-width: 200px!important;
                max-width: 200px!important;
                .ng-option {
                  font-size: 12px;
                  white-space: unset;
                }
              }
            }
            &.category-filter-wrapper {
              position: relative;
              .ng-select {
                max-width: 200px;
                min-width: 200px;
                &:focus {
                  outline: none;
                }
                &.ng-select-single {
                  &.ng-select-focused {
                    .ng-select-container {
                      border-color: #dddddd;
                      box-shadow: none;
                    }
                  }
                  .ng-select-container {
                    min-height: 30px;
                    height: 30px;
                    position: relative;
                    border: 1px solid #dddddd;
                    .ng-clear-wrapper {
                      display: flex;
                    }
                    .ng-arrow-wrapper {}
                    .ng-value-container {
                      // padding-right: 50px;
                      .ng-value {
                        font-size: 14px;
                      }
                      .ng-placeholder {
                        font-size: 14px;
                      }
                      .ng-input {
                        height: 30px;
                        top: 50%;
                        margin-top: -15px;
                      }
                    }
                  }
                }
              }
              .ng-dropdown-panel {
                min-width: 200px!important;
                max-width: 200px!important;
                .ng-option {
                  font-size: 12px;
                  white-space: unset;
                }
              }
            }
            &.date-filter-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                position: relative;
                /*&:first-child {
                  margin-right: 5px;
                }*/
                input {
                  background-color: transparent;
                  border: 1px solid #dddddd;
                  padding: 2px 0 2px 10px;
                  min-width: 130px;
                  width: 130px;
                  border-radius: 5px;
                  outline: none;
                  transition: all 300ms;
                  font-size: 15px;
                  &::placeholder { /* Most modern browsers support this now. */
                    color: #999;
                    font-size: 14px;
                  }
                  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #999;
                    font-size: 14px;
                  }
                  &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #999;
                    font-size: 14px;
                  }
                  &:focus {
                    box-shadow: none;
                  }
                }
                .clear-filter-icon {
                  position: absolute;
                  top: 0;
                  right: 10px;
                  margin-top: 6px;
                  cursor: pointer;
                  z-index: 10;
                  color: #999;
                }
              }
            }
            &.payments-date-filter {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: nowrap;
              label {
                display: none;
              }
              .calendar-toggle {
                font-size: 10px;
                background-color: #d3d3d363;
                padding: 2px 2px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  background-color: transparent;
                  padding: 3px 5px;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                  cursor: pointer;
                  -webkit-transition: all 250ms;
                  -moz-transition: all 250ms;
                  -ms-transition: all 250ms;
                  -o-transition: all 250ms;
                  transition: all 250ms;
                  &.active {
                    background-color: white;
                    -webkit-transition: all 250ms;
                    -moz-transition: all 250ms;
                    -ms-transition: all 250ms;
                    -o-transition: all 250ms;
                    transition: all 250ms;
                  }
                  &:first-child {
                    margin-right: 1px;
                  }
                  &:last-child {
                    margin-left: 1px;
                  }
                }
              }
              input {
                background-color: transparent;
                border: 1px solid #dddddd;
                padding: 2px 0 2px 10px;
                min-width: 150px;
                width: 150px;
                border-radius: 5px;
                outline: none;
                transition: all 300ms;
                height: 30px;
                font-size: 14px;
                margin-right: 10px;
                &::placeholder { /* Most modern browsers support this now. */
                  color: #999;
                  font-size: 14px;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: #999;
                  font-size: 14px;
                }
                &::-ms-input-placeholder { /* Microsoft Edge */
                  color: #999;
                  font-size: 14px;
                }
                &:focus {
                  box-shadow: none;
                }
              }
            }
            &.week-filter-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .date-output {
                min-width: 200px;
                margin-right: 5px;
                text-transform: uppercase;
                color: @theme_color;
                cursor: pointer;
              }
              .week-filter-controls {
                span {
                  color: @theme_color;
                }
                i {
                  padding: 0 3px;
                  cursor: pointer;
                  color: @theme_color;
                  /*&:first-child {
                    position: relative;
                    &:after {
                      content: '';
                      position: absolute;
                      right: 0;
                      top: 0;
                      height: 100%;
                      width: 1px;
                      background-color: @theme_color;
                    }
                  }*/
                }
              }
            }
            &.period-filter-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: nowrap;
              label {
                display: none;
              }
              input {
                background-color: transparent;
                border: 1px solid #dddddd;
                padding: 2px 0 2px 10px;
                min-width: 190px;
                border-radius: 5px;
                outline: none;
                transition: all 300ms;
                height: 30px;
                font-size: 14px;
                // margin-right: 10px;
                &::placeholder { /* Most modern browsers support this now. */
                  color: #999;
                  font-size: 14px;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: #999;
                  font-size: 14px;
                }
                &::-ms-input-placeholder { /* Microsoft Edge */
                  color: #999;
                  font-size: 14px;
                }
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
      .custom-checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 10px;
        .checkbox {
          cursor: pointer;
          -webkit-appearance: none;
          width: 25px;
          height: 25px;
          position: relative;
          margin-right: 10px;
          border: 1px solid #245d44;
          box-shadow: none;
          outline: none;
          border-radius: .25rem;
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
          &:checked {
            &:after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f00c";
              font-size: 16px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -8px;
              margin-top: -12px;
              color: @theme_color;
            }
          }
        }
        .checkbox-label {
          margin: 0 10px 0 20px;
        }
      }
    }
    &.no-search-filter {
      justify-content: flex-end;
      &.ss-filters {
        justify-content: space-between;
        .server-side-filters {
          width: auto;
          margin: 0;
        }
      }
      .search {
        display: none;
      }
    }
    label {
      margin: 0;
    }
    .search {
      display: flex;
      justify-content: space-between;
      position: relative;
      .search-label {
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -12px;
        color: lightgray;
        cursor: pointer;
      }
      .search-input {
        background-color: transparent;
        border: 1px solid #dddddd;
        padding: 5px 0 5px 10px;
        min-width: 300px;
        border-radius: 5px;
        outline: none;
        transition: all 300ms;
        font-size: 15px;
        &::placeholder { /* Most modern browsers support this now. */
          color: #999;
          font-size: 14px;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #999;
          font-size: 14px;
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #999;
          font-size: 14px;
        }
      }
    }
    .items-per-page {
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        font-size: 14px;
        margin-right: 5px;
      }
      .per-page-indicators {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        button {
          border-radius: 3px;
          background: transparent;
          color: @main_btn_color;
          border: 1px solid #e9ecef;
          width: 30px;
          height: 25px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.active {
            color: #ffffff;
            background-color: @main_btn_color;
            border: 1px solid @main_btn_color;
          }
          &:focus {
            outline: none;
          }
          &:not(:last-child) {
            margin-right: 3px;
          }
        }
      }
    }

    &.fullwidth-filters {
      justify-content: flex-start;
      .global-actions {
        &.active-controls {
          width: 100%;
          justify-content: space-between;
          .server-side-filters {
            width: auto;
            .week-filter-wrapper {
              position: relative;
              bs-datepicker-container {
                transform: translate3d(0px, 30px, 0px)!important;
              }
            }
          }
          .actions-wrapper {
            display: flex;
          }
        }
      }
      .search_ipp {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  .table-wrapper {
    position: relative;
    overflow-x: auto;
    table {
      width: 100%;
      table-layout: fixed;
      margin: 0;
      thead {
        display: table-header-group;
        overflow: auto;
        tr {
          th {
            border: 1px solid #e9ecef;
            position: relative;
            height: 50px;
            vertical-align: middle;
            cursor: pointer;
            padding: 5px 20px 5px 10px!important;
            overflow-wrap: break-word;
            font-weight: 600;
            color: @grey_text_color;
            .sort_icons {
              position: absolute!important;
              height: 20px!important;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
              right:15px;
              i {
                position: absolute;
                left: 0;
                vertical-align: middle;
                &.sort_active {
                  color: @main_btn_color;
                }
                &.fa-sort-up {
                  top: 1px;
                }
                &.fa-sort-down {
                  bottom: 1px;
                }
              }
            }
          }
        }
      }
      tbody {
        display: table-row-group;
        overflow: auto;
        tr {
          td {
            border: 1px solid #e9ecef;
            vertical-align: middle;
            height: 30px;
            padding: 5px 10px;
            overflow-wrap: break-word;
            color: @grey_text_color;
            i {
              vertical-align: middle;
              cursor: pointer;
            }
            &.pre-line-data {
              white-space: pre-line;
            }
            &.status_td {
              text-align: center;
              i {
                color: @inactive_status;
                &.active {
                  color: @active_status;
                }
              }
            }
          }
          &.hover_rows {
            &:hover {
              background-color: #42495a14;
              cursor: pointer;
            }
          }
          &.hover_rows_split_table {
            &:hover {
              cursor: pointer;
              td {
                background-color: #42495a14;
                &.no-bg {
                  background-color: #ffffff;
                  &:hover {
                    &~td {
                      background-color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    padding-top: 25px;
    .items-total-wrapper {
      font-size: 14px;
      color: #686868;
    }
    .table-pagination {
      margin: 0;
      .ngx-pagination {
        margin: 0;
        font-size: 14px;
        li {
          cursor: pointer;
          background-color: #ffffff;
          border: 1px solid #e9ecef;
          transition: all 250ms;
          width: 35px;
          text-align: center;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: @main_btn_color;
            border: 1px solid @main_btn_color;
            a {
              color: #ffffff;
            }
          }
          &.current {
            background-color: @main_btn_color;
            border: 1px solid @main_btn_color;
            a {
              color: #ffffff;
            }
          }
          a {
            text-decoration: none;
            transition: all 250ms;
            color: @main_btn_color;
            padding: 3px 0;
            &:hover {
              color: #ffffff;
              background-color: @main_btn_color;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

form {
  input, textarea {
    &:focus {
      outline: none!important;
      box-shadow: none!important;
    }
    /*&[type='checkbox'] {
      position: absolute;
      margin: -6.5px 0 0 0;
      top: 50%;
      left: 15px;
    }*/
  }
  button {
    &.btn {
      margin: 0 0 0 auto;
      display: block;
      padding: .375rem 2.75rem;
      &:focus, &:active {
        outline: none;
        box-shadow: none!important;
        background-color: #5cb85c!important;
        border: 1px solid #5cb85c!important;
      }
      &[type='submit'] {
        background-color: #5cb85c;
        border: 1px solid #5cb85c;
      }
    }
  }
}
.main-action-btn {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: @main_btn_color;
  border: 2px solid @main_btn_color;
  color: #ffffff;
  min-width: 200px;
  min-height: 32px;
  margin: 0 auto;
  display: block;
  transition: all 250ms;
  line-height: 32px;
  &.contra-colors {
    background-color: #ffffff;
    color: @main_btn_color;
    &:hover {
      background-color: @main_btn_color;
      color: #ffffff;
    }
  }
  &:hover {
    background-color: #ffffff;
    color: @main_btn_color;
  }
  &:focus {
    outline: none;
  }
}
.secondary-action-btn {
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: #ffffff;
  border: 2px solid @main_btn_color;
  color: @main_btn_color;
  min-width: 200px;
  min-height: 32px;
  margin: 0 auto;
  display: block;
  transition: all 250ms;
  line-height: 32px;
  &:hover {
    background-color: @main_btn_color;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}
.btn-primary,
.modal-submit-btn {
  margin: 0 0 0 auto;
  display: block;
  padding: 5px 10px;
  background-color: @main_btn_color;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
    background-color: @main_btn_color;
  }
}
.main-cancel-btn {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid @main_cancel_btn_color;
  color: @main_cancel_btn_color;
  min-width: 200px;
  min-height: 32px;
  margin: 0 auto;
  display: block;
  transition: all 250ms;
  line-height: 32px;
  &:hover {
    background-color: @main_cancel_btn_color;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}
.modal-cancel-btn  {
  margin: 0 0 0 5px!important;
  display: block;
  padding: 5px 10px;
  background-color: @main_cancel_btn_color;
  border: none;
  color: #ffffff;
  border-radius: 5px!important;
  &:hover {
    opacity: 0.8;
    background-color: @main_cancel_btn_color;
  }
}
.daterangepicker-wr {
  position: relative;
  margin-right: 20px;
  .daterangepicker-input {
    //position: initial;
    padding: 4px 10px;
    height: auto;
  }
  .daterangepicker {
    min-width: 685px;
    .drp-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.form-control-feedback {
  p {
    font-size: 12px;
    color: red;
    margin-bottom: 0;
  }
}


.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  .search-filter-wr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .search {
    display: flex;
    justify-content: space-between;
    position: relative;
    .search-label {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -12px;
      color: lightgray;
      cursor: pointer;
    }
    .search-input {
      background-color: transparent;
      border: 1px solid #dddddd;
      padding: 5px 0 5px 10px;
      min-width: 200px;
      border-radius: 5px;
      outline: none;
      transition: all 300ms;
      font-size: 15px;
      color: #666666;
    }
    &::placeholder {
      color: #999;
      font-size: 14px;
    }
    &::-ms-input-placeholder {
      color: #999;
      font-size: 14px;
    }
  }
}

.tooltip_wrapper {
  display: inline-block;
  position: relative;
  .icon {
    font-size: 12px;
    cursor: help;
    margin-left: 3px;
    &:hover + .tooltip_body {
      display: block;
    }
  }
  .tooltip_body {
    display: none;
    position: absolute;
    min-width: 230px;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-size: 12px;
    padding: 10px;
    word-break: break-word;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      transform: rotate(-45deg) translateX(-50%);
      background: rgba(0, 0, 0, 0.9);
      position: absolute;
      bottom: 0px;
      left: 50%;
    }
  }
}

.horizontal-tabs {
  margin: 20px 0;
  .breadcrumbs {
    margin-bottom: 20px;
  }
  .tabs-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #E0E3EA;
    .tab {
      //width: 100%;
      text-align: center;
      cursor: pointer;
      position: relative;
      font-size: 13px;
      text-transform: uppercase;
      color: #666666;
      padding: 0px 30px 4px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: @main_btn_color;
        height: 0;
        transition: height 100ms;
      }
      &.active {
        font-weight: 500;
        color: @main_btn_color;
        &:after {
          height: 2px;
          transition: height 100ms;
        }
      }
      &:nth-child(2) {
        margin: 0 5px;
      }
    }
  }
}
