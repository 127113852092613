/* You can add global styles to this file, and also import other style files */
@import "assets/less/fonts";
@import "assets/less/vars";
@import "assets/less/unified";


html {
  position: relative;
  min-height: 100%;
  overflow-y: scroll;
}
body {
  &.modal-open {
    padding: 0!important;
  }
  main {
    margin: 25px;
  }
}

app-alert {
  z-index: 999999;
  overflow: hidden;
  position: fixed;
  top: 55px;
  right: 15px;
}

form {
  .form-group {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    input[type=text], input[type=number], textarea {
      padding: 10px;
      border: 1px solid @form_border;
      border-radius: 3px;
      //color: @grey_text_color;
      &::placeholder {
        color: @grey_text_color;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: @grey_text_color;
      }
      &::-ms-input-placeholder {
        color: @grey_text_color;
      }
    }
    textarea {
      min-height: 45px;
    }
  }
}


.ngx-pagination {
  margin: 0;
  font-size: 14px;
  li {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #CDCED9;
    transition: all 250ms;
    border-radius: 3px!important;
    width: 35px;
    text-align: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: @theme_color;
      border: 1px solid @theme_color;
      a {
        color: #ffffff;
      }
    }
    &.current {
      background-color: @theme_color;
      border: 1px solid @theme_color;
      a {
        color: #ffffff;
      }
    }
    a {
      text-decoration: none;
      transition: all 250ms;
      color: #CDCED9;
      padding: 3px 0!important;
      &:hover {
        color: #ffffff!important;
        background-color: @theme_color;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
.progress {
  position: relative;
  border-radius: 8px;
  border: 1px solid #CDCED9;
  background-color: #ffffff;
  bar {
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 30px;
      margin-left: -15px;
      color: black;
      font-size: 12px;
    }
  }
  bar {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    animation: progress-bar-stripes 1s linear infinite;
  }
  &.danger {
    bar {
      background-color: #ff0000!important;
    }
  }
  &.warning {
    bar {
      background-color: #ffc107!important;
    }
  }
  &.normal {
    bar {
      background-color: #51a5ff!important;
      i {
        // color: #ffffff;
      }
    }
  }
}
@keyframes progress-bar-stripes {
  0% { background-position: 0 16px; }
  100% { background-position: 0 0; }
}


.modal {
  .modal-dialog {
    .modal-content {
      &>div {
        border-color: #E7E7ED;
      }
      .modal-header {
        position: relative;
        .modal-title {
          font-weight: bold;
          font-size: 20px;
          color: #272833;
        }
        .close {
          &:focus {
            outline: none;
          }
        }
      }
      .modal-body {}
      .modal-footer {
        .modal-cancel-btn {
          background-color: @theme_color;
          color: #ffffff;
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          border: none;
          margin: 0;
          padding: 5px 10px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.container, .container-fluid {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
  padding: 25px;
  .row {
    margin: 0 auto;
    .top-bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      min-height: 32px;
      margin-bottom: 30px;
      &.horizontal-tabs {
        .breadcrumbs {
          margin-bottom: 20px;
        }
        .tabs-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: nowrap;
          .tab {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid @theme_color;
            cursor: pointer;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              background-color: @theme_color;
              height: 0;
              transition: height 100ms;
            }
            &.active {
              font-weight: 500;
              &:after {
                height: 3px;
                transition: height 100ms;
              }
            }
            &:nth-child(2) {
              margin: 0 5px;
            }
          }
        }
      }
      .page-title {
        font-size: 24px;
        color: #666666;
        font-weight: 500;
      }
      .breadcrumbs {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .breadcrumb-item {
          font-size: 18px;
          a {
            color: @link_color;
            text-decoration: underline;
          }
          &:last-child {
            a {text-decoration: none;}
          }
          &:not(:first-child) {
            &:before {
              content: '/';
              //font-family: "Font Awesome 5 Free";
              //font-weight: 900;
              //content: "\f054";
              color: @link_color;
              font-size: 18px;
              transition: all 500ms;
            }
          }
        }
      }
    }
  }
}
